import React from 'react';
import PropTypes from 'prop-types';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const DesktopAnchorLink = ( { label, link } ) => {
	var linkAnchor = link.replace( ' ', '_' );

	return (
		<AnchorLink to={linkAnchor} title={link}>
			<span>{label}</span>
	    </AnchorLink>
	);
};

DesktopAnchorLink.defaultProps = { className: '', link: '', label: '' };

DesktopAnchorLink.propTypes = { className: PropTypes.string, label: PropTypes.string, link: PropTypes.string };

export default DesktopAnchorLink;
